<template>
  <div class="modal">
    <div class="title">
      <span>Insira as informações abaixo</span>
    </div>

    <div class="form">
      <v-form ref="form" class="internal-form">
        <z-input
          v-model="item.name"
          label="Nome"
          :hide-details="true"
          type="text"
          :rules="[(v) => !!v || 'Campo obrigatório']"
        />

        <z-select
          v-model="item.dataType"
          :items="dataTypesProp"
          itemText="name"
          itemValue="value"
          label="Tipo do campo"
          :hide-details="true"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          type="text"
        />

        <z-select
          v-if="item.dataType === 'datetime'"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :items="dateTimeFormatsProp"
          itemText="format"
          v-model="item.format"
          itemValue="format"
          label="Formato do campo"
          :hide-details="true"
        />
        <z-select
          v-else-if="item.dataType === 'date'"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :items="dateFormatsProp"
          itemText="format"
          v-model="item.format"
          itemValue="format"
          label="Formato do campo"
          :hide-details="true"
        />
      </v-form>
    </div>

    <div class="actions">
      <z-btn text="Cancelar" color="primary" :rounded="true" @click="$emit('close')" />
      <z-btn
        text="Adicionar campo"
        primary
        color="primary"
        :rounded="true"
        @click="createCamp"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldsListProp: { type: Array, default: null, required: true },
    dataTypesProp: { type: Array, default: null, required: true },
    dateTimeFormatsProp: { type: Array, default: null, required: true },
    dateFormatsProp: { type: Array, default: null, required: true },
  },
  data() {
    return {
      item: { dataType: "", name: "", format: "" },
    };
  },
  watch: {
    "item.dataType"(nv) {
      if (nv != "date" || nv != "datetime") this.item.format = "";
    },
  },
  methods: {
    createCamp() {
      if (this.$refs.form.validate()) {
        if (!this.fieldsListProp.includes(this.item.name)) {
          this.$emit("create", this.item);
        } else this.$toast.warning("Esse nome de chave já foi cadastrado!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: white;
  padding: $z-s-2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .internal-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
